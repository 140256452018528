<template>
  <div class="OCT">
    <h3 style="padding: 30px 0;">OCT</h3>

    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>RNFL平均厚度</li>
          <li>盘沿面积</li>
          <li>视盘</li>
          <li>平均CD比率</li>
          <li>垂直CD比率</li>
          <li>视杯体积</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OD_RNFL, -30, 20)?'cw':'', srval==='CL_RE_OCT_OD_RNFL'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OD_RNFL, 'CL_RE_OCT_OD_RNFL', 0)" v-model="postData.CL_RE_OCT_OD_RNFL" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OD_Rim, -10, 9)?'cw':'', srval==='CL_RE_OCT_OD_Rim'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OD_Rim, 'CL_RE_OCT_OD_Rim', 0)" v-model="postData.CL_RE_OCT_OD_Rim" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OD_OpticDiskArea, 0, 180)?'cw':'', srval==='CL_RE_OCT_OD_OpticDiskArea'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OD_OpticDiskArea, 'CL_RE_OCT_OD_OpticDiskArea', 0)" v-model="postData.CL_RE_OCT_OD_OpticDiskArea" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OD_AvgCDRate, 20, 100)?'cw':'', srval==='CL_RE_OCT_OD_AvgCDRate'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OD_AvgCDRate, 'CL_RE_OCT_OD_AvgCDRate', 0)" v-model="postData.CL_RE_OCT_OD_AvgCDRate" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OD_VerticalCDRate, 20, 100)?'cw':'', srval==='CL_RE_OCT_OD_VerticalCDRate'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OD_VerticalCDRate, 'CL_RE_OCT_OD_VerticalCDRate', 0)" v-model="postData.CL_RE_OCT_OD_VerticalCDRate" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OD_OpticCupVolume, 20, 100)?'cw':'', srval==='CL_RE_OCT_OD_OpticCupVolume'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OD_OpticCupVolume, 'CL_RE_OCT_OD_OpticCupVolume', 0)" v-model="postData.CL_RE_OCT_OD_OpticCupVolume" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OS_RNFL, -30, 20)?'cw':'', srval==='CL_RE_OCT_OS_RNFL'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OS_RNFL, 'CL_RE_OCT_OS_RNFL', 0)" v-model="postData.CL_RE_OCT_OS_RNFL" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OS_Rim, -10, 9)?'cw':'', srval==='CL_RE_OCT_OS_Rim'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OS_Rim, 'CL_RE_OCT_OS_Rim', 0)" v-model="postData.CL_RE_OCT_OS_Rim" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OS_OpticDiskArea, 0, 180)?'cw':'', srval==='CL_RE_OCT_OS_OpticDiskArea'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OS_OpticDiskArea, 'CL_RE_OCT_OS_OpticDiskArea', 0)" v-model="postData.CL_RE_OCT_OS_OpticDiskArea" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OS_AvgCDRate, 20, 100)?'cw':'', srval==='CL_RE_OCT_OS_AvgCDRate'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OS_AvgCDRate, 'CL_RE_OCT_OS_AvgCDRate', 0)" v-model="postData.CL_RE_OCT_OS_AvgCDRate" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OS_VerticalCDRate, 20, 100)?'cw':'', srval==='CL_RE_OCT_OS_VerticalCDRate'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OS_VerticalCDRate, 'CL_RE_OCT_OS_VerticalCDRate', 0)" v-model="postData.CL_RE_OCT_OS_VerticalCDRate" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OCT_OS_OpticCupVolume, 20, 100)?'cw':'', srval==='CL_RE_OCT_OS_OpticCupVolume'?'xz':'']" @focus="getgb(postData.CL_RE_OCT_OS_OpticCupVolume, 'CL_RE_OCT_OS_OpticCupVolume', 0)" v-model="postData.CL_RE_OCT_OS_OpticCupVolume" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
      <!--       <div style="display: inline-block;">-->

      <!-- -->
      <!--       </div>-->
    </div>

<!--    <UPfile :type="'OCT'"></UPfile>-->

    <UPFILE :imgtype="'OCT'" :wjtype="'OCT'" :datas.sync="contentData"></UPFILE>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.CL_RE_OCT_Remark">
      </el-input>
    </div>
    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>



<script>
import pf from "@/publicFn/baseFn.js"
/*
2、OCT栏目增加6对项次，都是字符串类型
        /// RNFL平均厚度 OD
CL_RE_OCT_OD_RNFL = "";
        /// RNFL平均厚度 OS
CL_RE_OCT_OS_RNFL = "";
        /// 盘沿面积 OD
CL_RE_OCT_OD_Rim = "";
        /// 盘沿面积 OS
CL_RE_OCT_OS_Rim = "";
        /// 视盘 OD
CL_RE_OCT_OD_OpticDiskArea = "";
        /// 视盘 OS
CL_RE_OCT_OS_OpticDiskArea = "";
        /// 平均CD比率 OD
CL_RE_OCT_OD_AvgCDRate = "";
        /// 平均CD比率 OS
CL_RE_OCT_OS_AvgCDRate = "";
        /// 垂直CD比率 OD
CL_RE_OCT_OD_VerticalCDRate = "";
        /// 垂直CD比率 OS
CL_RE_OCT_OS_VerticalCDRate = "";
        /// 视杯体积 OD
CL_RE_OCT_OD_OpticCupVolume = "";
        /// 视杯体积 OS
CL_RE_OCT_OS_OpticCupVolume = "";
* */
export default {
  name: "OCT",
  data() {
    return {
      contentData: {},
      v1: '',
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 1,
      postData: {
        CL_RE_OCT_OD_RNFL: '',
        CL_RE_OCT_OD_Rim: '',
        CL_RE_OCT_OD_OpticDiskArea: '',
        CL_RE_OCT_OD_AvgCDRate: '',
        CL_RE_OCT_OD_VerticalCDRate: '',
        CL_RE_OCT_OD_OpticCupVolume: '',

        CL_RE_OCT_OS_RNFL: '',
        CL_RE_OCT_OS_Rim: '',
        CL_RE_OCT_OS_OpticDiskArea: '',
        CL_RE_OCT_OS_AvgCDRate: '',
        CL_RE_OCT_OS_VerticalCDRate: '',
        CL_RE_OCT_OS_OpticCupVolume: '',
      },
      datas: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_ExpertId: this.$store.state.users.user.UserId,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_Type: 'CL_RE_OCT',
        S_OP_Model: 'CL_RE_OCT180726',
        S_OP_Json: ''
      }
    }
  },
  watch: {
    contentData: {
      handler(n, o) {
        if (n) {
          console.log(n)
          for (let key in n) {

            if (n[key]) this.postData[key] = n[key]
          }
        }
      }
    }
  },
  created() {
    this._http.post(pf.getUrl('PS_all_Normal_getList'), {
      "token": {
        "TimeSpan": 1566827733,
        "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
        S_OP_Type: 'CL_RE_OCT',
        ChooseId: this.$store.state.physicianVisits.xzConsulting
      }
    })
    .then(res => {
      if (res.GetListResult && res.GetListResult.length) {
        this.datas = JSON.parse(JSON.stringify(res.GetListResult[0]))
        this.postData = pf.decoding(this.datas.S_OP_Json)
      }
    })
    document.addEventListener('click', this.getXY, false)
  },
  methods: {
    keyd(e) {
      // e.returnValue= false
      if (pf.isMobile()) {
        e.returnValue= false
      }
      // console.log(e)
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      if (pf.isMobile()) {
        document.activeElement.blur();
      }


      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData, this.srval, e)
      if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
        if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
          this.$set(this.postData, this.srval, '+'+e)
        }
      }
    },

    save() {
      let log = this.$loading({
        text: '保存中...'
      })
      let obj = this.datas
      obj.S_OP_Json =  pf.conversionJson(this.postData)
      this._http.post(pf.getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: obj
      })
      .then(res => {
        log.close()
        if (res.UpdateResult) {
          this.$store.commit('isbc/uptjk', {
            keys: 'oct',
            val: true
          })
          this.$alert('保存成功！', '提示')
        }else {
          this.$alert('保存失败请稍后再试！', '提示')
        }
      })
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile'),
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPFILE: () => import('../../components/sbData')

  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  }
}
</script>

<style scoped lang="scss">
.OCT {
  padding: 10vh 0;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
.bz {
  padding-top: 30px;
  p{
    padding-bottom: 10px;
  }
}
.srA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
      .syph {
        position: absolute;
        top: -1.4em;
        text-align: center;
        width: 100%;
      }
    }
    li:nth-child(1) {
      width: 4vw;
      justify-content: start;
      margin-right: 5px;
    }
  }
  .w8 { li {width: 7vw;} }
  .tj {
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      margin-top: 21px;
    }
  }
  .last {
    margin-left: 50px;
    margin-top: 32px;
  }
}
.padding-20 {
  padding: 20px 0;
}
</style>
